<template>
    <div class="popup popup-dark popup-logo popup-open" :class="className()">
        <div class="popup-backdrop" @click="close"></div>
        <div class="popup-inner">
            <div v-if="isClosableInternal" class="icon icon-close-dark" :title="t.close" @click="close"></div>
            <div class="popup-inner-scroll-container">
                <div v-if="loading" class="spinner" />
                <slot v-else></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: ["options", "loading", "is-closable"],
    data() {
        return {
            isClosableInternal: this.isClosable === undefined || this.isClosable,
            t: window.globalJs.translations.frontend,
        }
    },
    created() {
        window.addEventListener("keyup", this.closeOnESC, { passive: true })
        document.querySelectorAll(".card").forEach((c) => c.classList.add("no-hover"))
    },
    destroyed() {
        window.removeEventListener("keyup", this.closeOnESC)
        document.querySelectorAll(".card").forEach((c) => c.classList.remove("no-hover"))
    },
    methods: {
        close(event) {
            if (!this.isClosableInternal) {
                return
            }

            event.stopPropagation()
            this.$emit("close")
        },
        className() {
            let className = ""
            if (!this.options || !this.options.fixedWidth) {
                className += "popup-auto-width"
            }
            return className
        },
        closeOnESC(event) {
            if (parseInt(event.keyCode) === 27) {
                this.close(event)
            }
        },
    },
}
</script>
